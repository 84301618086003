<template>
    <div class="login-container"></div>
</template>

<script>
import { getToken } from '@/utils/auth';
import { getRedirectUrl } from '@/api/auth';
export default {
    name: 'login',
    data() {
        return {
            token: '',
        };
    },
    mounted() {
        this.token = getToken();
        // 已登录，跳转首页
        if (this.token) {
            this.$router.push('/');
        } else {
            this.handleRedirect();
        }
    },
    methods: {
        async handleRedirect() {
            // 生成随机码，传递服务端获取重定向地址，同时进行本地存储。
            // 认证成功后，登录时需携带此随机码

            const key = ~~(Math.random() * 1e10);
            localStorage.setItem(process.env.VUE_APP_LOGIN_KEY, key);

            // 获取重定向地址
            const [response, error] = await getRedirectUrl(key);
            if (error) {
                return this.$message.error(error.msg);
            }

            // 检测用户是否有目标页面,如果有，进行存储。
            // 如果没有 from，则将 from 标记为 '/'，以防止回跳后获取到上一次的跳转目标
            const { from } = this.$route.query || '/';
            localStorage.setItem(process.env.VUE_APP_TARGET_URL_KEY, from);

            setTimeout(() => {
                // 跳转授权页面
                location.href = response.data.url;
            }, 200);

            // 跳转授权页面
            // location.href = response.data.url;
        },
    },
};
</script>
